import { Link, RouteObject } from 'react-router-dom';
import {
  DemoWrapper,
  EnrichmentConfigProvider,
  EnrichmentDemo,
  EnrichmentImportedDataProvider,
  MdmDemo
} from '../pages/Demo';
import { ProductEnrichmentProvider } from '../pages/Demo/Enrichment/Enrichment';

export const DEMO_ROUTES: RouteObject[] = [
  {
    path: '/demo',
    handle: { crumb: () => <Link to="/demo">Demos</Link> },
    element: <DemoWrapper />,
    children: [
      {
        path: 'mdm',
        element: <MdmDemo />,
        handle: {
          crumb: () => {
            return <p>MDM</p>;
          }
        }
      },
      {
        path: 'enrichment',
        element: (
          <EnrichmentConfigProvider>
            <EnrichmentImportedDataProvider>
              <ProductEnrichmentProvider>
                <EnrichmentDemo />
              </ProductEnrichmentProvider>
            </EnrichmentImportedDataProvider>
          </EnrichmentConfigProvider>
        ),
        handle: {
          crumb: () => {
            return <p>Enrichment</p>;
          }
        }
      }
    ]
  }
];
