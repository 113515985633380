import { useEnrichmentConfig } from './EnrichmentConfig';
import clsx from 'clsx';

export function EnrichmentConfigPanelProduct() {
  const { productDescription, verifyProduct, updateProductDescription, toggleVerifyProduct } = useEnrichmentConfig();

  return (
    <div className="w-full flex flex-col justify-start gap-y-6">
      <div>
        <h3 className="text-xl">Description</h3>
        <p className="my-2 text-sm text-gray-600">Provide a brief description of the products being enriched.</p>
        <input
          className="flex-grow shadow-md border border-gray-300 rounded-md w-full text-sm h-8"
          value={productDescription}
          onChange={(event) => {
            updateProductDescription(event.target.value);
          }}
        />
      </div>
      <div>
        <h3 className="text-xl">Verify Product</h3>
        <p className="my-2 text-sm text-gray-600">Verify product is as described prior to enrichment.</p>

        <button
          onClick={() => {
            toggleVerifyProduct();
          }}
          className={clsx(
            'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            verifyProduct ? 'bg-indigo-600' : 'bg-gray-200'
          )}
          role="switch"
          aria-checked="false"
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={clsx(
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              verifyProduct ? 'translate-x-5' : 'translate-x-0'
            )}
          ></span>
        </button>
      </div>
    </div>
  );
}
