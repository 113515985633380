import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { HIGHBOURNE_HEATING_ENRICHMENT_GROUPS } from './EnrichmentGroupConfigs';
import { EnrichmentGroupState, Source, SourceEnrichmentKind } from './types';
import { enrichmentClient } from './api';

interface EnrichmentConfigContextProps {
  sources: Source[];
  sourceBlacklist: string[];
  sourceLimit: number;
  enrichmentGroups: EnrichmentGroupState;
  productDescription: string;
  verifyProduct: boolean;
  loadingSuggestedGroup: boolean;
  enrichAllBatchSize: number;
  updateSource: (idx: number, source: Source) => void;
  removeSource: (idx: number) => void;
  addSource: () => void;
  updateBlacklistSource: (idx: number, value: string) => void;
  removeBlacklistSource: (idx: number) => void;
  addBlacklistSource: () => void;
  setSourceLimit: React.Dispatch<React.SetStateAction<number>>;
  updateEnrichmentField: (enrichmentGroupIndex: number, fieldIndex: number, value: string) => void;
  updateEnrichmentFieldKind: (enrichmentGroupIndex: number, fieldIndex: number, kind: SourceEnrichmentKind) => void;
  addEnrichmentField: (enrichmentGroupIndex: number) => void;
  removeEnrichmentField: (enrichmentGroupIndex: number, fieldIndex: number) => void;
  addEnrichmentGroup: () => void;
  renameEnrichmentGroup: (enrichmentGroupIndex: number, value: string) => void;
  removeEnrichmentGroup: (enrichmentGroupIndex: number) => void;
  updateProductDescription: (newDescription: string) => void;
  toggleVerifyProduct: () => void;
  suggestEnrichmentGroup: () => void;
  setEnrichAllBatchSize: (value: number) => void;
}

const EnrichmentConfigContext = createContext<EnrichmentConfigContextProps | undefined>(undefined);

export function EnrichmentConfigProvider({ children }: React.PropsWithChildren<{}>) {
  // TODO: Prefix localstorage items with enirchment demo
  const [productDescription, setProductDescription] = useState(() => {
    return localStorage.getItem('productDescription') || '';
  });
  const [verifyProduct, setVerifyProduct] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem('verifyProduct') || 'false');
  });
  const [sources, setSources] = useState<Source[]>(() => {
    return JSON.parse(localStorage.getItem('sources') || '[]');
  });
  const [sourceBlacklist, setSourceBlacklist] = useState<string[]>(() => {
    return JSON.parse(localStorage.getItem('sourceBlacklist') || '[]');
  });
  const [sourceLimit, setSourceLimit] = useState(() => {
    return JSON.parse(localStorage.getItem('sourceLimit') || '5');
  });
  const [enrichmentGroups, setEnrichmentGroups] = useState<EnrichmentGroupState>(() => {
    return JSON.parse(localStorage.getItem('enrichmentGroups') || JSON.stringify(HIGHBOURNE_HEATING_ENRICHMENT_GROUPS));
  });
  const [newGroupsAdded, setNewGroupsAdded] = useState(0);
  const [blacklistItemsAdded, setBlacklistItemsAdded] = useState(0);
  const [sourcesAdded, setSourcesAdded] = useState(0);
  const [loadingSuggestedGroup, setLoadingSuggestedGroup] = useState(false);
  const [enrichAllBatchSize, setEnrichAllBatchSize] = useState(() => {
    return parseInt(localStorage.getItem('enrichAllBatchSize') || '1', 10);
  });

  useEffect(() => {
    localStorage.setItem('productDescription', productDescription);
  }, [productDescription]);

  useEffect(() => {
    localStorage.setItem('verifyProduct', JSON.stringify(verifyProduct));
  }, [verifyProduct]);

  useEffect(() => {
    localStorage.setItem('sources', JSON.stringify(sources));
  }, [sources]);

  useEffect(() => {
    localStorage.setItem('sourceBlacklist', JSON.stringify(sourceBlacklist));
  }, [sourceBlacklist]);

  useEffect(() => {
    localStorage.setItem('sourceLimit', JSON.stringify(sourceLimit));
  }, [sourceLimit]);

  useEffect(() => {
    localStorage.setItem('enrichmentGroups', JSON.stringify(enrichmentGroups));
  }, [enrichmentGroups]);

  useEffect(() => {
    localStorage.setItem('enrichAllBatchSize', enrichAllBatchSize.toString());
  }, [enrichAllBatchSize]);

  const updateEnrichmentField = useCallback((enrichmentGroupIndex: number, fieldIndex: number, value: string) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = [...groupCopy.fields];
      groupCopy.fields[fieldIndex].fieldName = value;

      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const updateEnrichmentFieldKind = useCallback(
    (enrichmentGroupIndex: number, fieldIndex: number, kind: SourceEnrichmentKind) => {
      setEnrichmentGroups((prev) => {
        const prevCopy = [...prev];
        const groupCopy = { ...prev[enrichmentGroupIndex] };

        groupCopy.fields = [...groupCopy.fields];
        groupCopy.fields[fieldIndex].kind = kind;

        prevCopy[enrichmentGroupIndex] = groupCopy;

        return prevCopy;
      });
    },
    []
  );

  const addEnrichmentField = useCallback((enrichmentGroupIndex: number) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = [...groupCopy.fields, { fieldName: `New Field`, kind: SourceEnrichmentKind.Extracted }];
      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const removeEnrichmentField = useCallback((enrichmentGroupIndex: number, fieldIndex: number) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = groupCopy.fields.filter((_, index) => index !== fieldIndex);

      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const addEnrichmentGroup = useCallback(() => {
    setEnrichmentGroups((prev) => [...prev, { groupName: `New Group ${newGroupsAdded + 1}`, fields: [] }]);
    setNewGroupsAdded((prev) => prev + 1);
  }, [newGroupsAdded]);

  const renameEnrichmentGroup = useCallback((enrichmentGroupIndex: number, value: string) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.groupName = value;
      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const removeEnrichmentGroup = useCallback((enrichmentGroupIndex: number) => {
    setEnrichmentGroups((prev) => {
      let prevCopy = [...prev];

      return prevCopy.filter((_, index) => index !== enrichmentGroupIndex);
    });
  }, []);

  const updateSource = (idx: number, source: Source) => {
    setSources((prev) => [...prev.slice(0, idx), source, ...prev.slice(idx + 1, prev.length)]);
  };
  const removeSource = (idx: number) => {
    setSources((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]);
  };
  const addSource = () => {
    setSources((prev) => [...prev, { name: `Source ${sourcesAdded + 1}`, aiGenerated: false }]);
    setSourcesAdded((prev) => prev + 1);
  };
  const updateBlacklistSource = (idx: number, value: string) => {
    setSourceBlacklist((prev) => [...prev.slice(0, idx), value, ...prev.slice(idx + 1, prev.length)]);
  };
  const removeBlacklistSource = (idx: number) => {
    setSourceBlacklist((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]);
  };
  const addBlacklistSource = () => {
    setSourceBlacklist((prev) => [...prev, `Blacklist source ${blacklistItemsAdded + 1}`]);
    setBlacklistItemsAdded((prev) => prev + 1);
  };

  const toggleVerifyProduct = () => {
    setVerifyProduct((prev) => !prev);
  };

  const updateProductDescription = (newDescription: string) => {
    setProductDescription(newDescription);
  };

  const suggestEnrichmentGroup = async () => {
    setLoadingSuggestedGroup(true);

    const suggestedFields = await enrichmentClient.suggestProductEnrichmentGroup({
      productDescription,
      enrichmentGroups
    });

    if (suggestedFields.length > 0) {
      setEnrichmentGroups((prev) => [...prev, { groupName: `Suggested Fields`, fields: suggestedFields }]);
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    setLoadingSuggestedGroup(false);
  };

  return (
    <EnrichmentConfigContext.Provider
      value={{
        sources,
        sourceBlacklist,
        sourceLimit,
        enrichmentGroups,
        productDescription,
        verifyProduct,
        loadingSuggestedGroup,
        enrichAllBatchSize,
        setEnrichAllBatchSize,
        setSourceLimit,
        updateSource,
        removeSource,
        addSource,
        updateBlacklistSource,
        removeBlacklistSource,
        addBlacklistSource,
        updateEnrichmentField,
        updateEnrichmentFieldKind,
        addEnrichmentField,
        removeEnrichmentField,
        addEnrichmentGroup,
        renameEnrichmentGroup,
        removeEnrichmentGroup,
        updateProductDescription,
        toggleVerifyProduct,
        suggestEnrichmentGroup
      }}
    >
      {children}
    </EnrichmentConfigContext.Provider>
  );
}

export const useEnrichmentConfig = () => {
  const context = useContext(EnrichmentConfigContext);
  if (context === undefined) {
    throw new Error('useEnrichmentConfigContext must be used within an EnrichmentConfigProvider');
  }
  return context;
};
