import { env } from '../../../env';
import { FetchFunc, HttpClient, HttpEnrichmentClient, linearBackoffFactory } from './client';

export const backoffFunc = linearBackoffFactory(200);

export const apiUrl = env.API_ENDPOINT_ENRICHMENT;

// @ts-ignore
export const fetchFunc: FetchFunc = (url: string, init?: RequestInit) => fetch(url, init);

export const httpClient = new HttpClient({ fetch: fetchFunc, backoffFunc, maxAttempts: 3 });

export const enrichmentClient = new HttpEnrichmentClient(httpClient, apiUrl);
