export type EnrichmentGroup = { groupName: string; fields: { fieldName: string; kind: SourceEnrichmentKind }[] };
export type EnrichmentGroupState = EnrichmentGroup[];

export type Source = {
  name: string;
  aiGenerated: boolean;
};

export type EnrichmentResult = {
  columnName: string;
  values: { source: string; value: string | number }[];
};

export type EnrichmentColumnGroupResult = {
  columnGroupName: string;
  results: EnrichmentResult[];
  enrichedCount: number;
  enrichedPct: number;
  nullCount: number;
};

export type EnrichmentResponse = {
  enrichment: EnrichmentColumnGroupResult[];
  summary: string;
  cleanQuery: string;
  processingTime: number;
  enrichedCount: number;
  enrichedPct: number;
  nullCount: number;
  source: string;
  images: ImageSearchResult[];
};

export type SourceEnrichment = {
  source: string;
  enrichment: EnrichmentColumnGroupResult[];
  processingTime: number;
  enrichedCount: number;
  enrichedPct: number;
  nullCount: number;
};

export enum SourceEnrichmentKind {
  Generated = 'generated',
  Extracted = 'extracted'
}

export type SourceEnrichmentField = {
  fieldName: string;
  kind: SourceEnrichmentKind;
};

export type SourceEnrichmentGroup = {
  groupName: string;
  fields: SourceEnrichmentField[];
};

export type SourceEnrichmentValueData = string | number | boolean;

export type SourceEnrichmentValue = {
  value: SourceEnrichmentValueData | undefined;
  sourceName: string;
  sourceUrl: string;
};

export type SourceEnrichmentFieldResult = {
  fieldName: string;
  kind: SourceEnrichmentKind;
  values: SourceEnrichmentValue[];
};

export type EnrichmentStats = {
  enrichedCount: number;
  enrichedPct: number;
  nullCount: number;
};

export type SourceEnrichmentGroupResult = {
  groupName: string;
  fields: SourceEnrichmentFieldResult[];
  summary?: string;
  stats?: EnrichmentStats;
};

export type SourceEnrichmentResponse = {
  groups: SourceEnrichmentGroupResult[];
  cleanQuery?: string;
  summary?: string;
  processingTime?: number;
  stats?: EnrichmentStats;
  images?: ImageSearchResult[];
};

export type ImageSearchResult = {
  name: string;
  thumbnailUrl: string;
  contentUrl: string;
  hostPageUrl: string;
  width: number;
  height: number;
  encodingFormat?: string;
};

export type ImageSearchResponse = {
  images: ImageSearchResult[];
};

export type SourceEnrichmentResultState = {
  [key: string]: SourceEnrichmentResponse;
};

export type ImageSearchResultMap = { [key: string]: ImageSearchResult[] };

export type ColumnIdentifier = {
  columnName: string;
  value: string;
};

export type EnrichmentDataTableHeader = {
  id: string;
  name: string;
  kind: string | null;
};

export type EnrichmentDataTableRow = {
  id: string;
  cells: EnrichmentDataTableCell[];
};

export type EnrichmentDataTableCell = {
  id: string;
  value: string;
};

export type SearchWebRequestBody = {
  query: string;
  sources: string[];
  sourceBlacklist: string[];
  sourceLimit: number;
};

export type WebPageSearchResult = {
  name: string;
  url: string;
  snippet: string;
  tags: string;
  datePublished: string;
  dateLastCrawled: string;
};

export type EnrichmentRequestOptions = {
  identifiers: ColumnIdentifier[];
  enrichmentGroups: EnrichmentGroup[];
  sources: string[];
  sourceBlacklist: string[];
  sourceLimit: number;
};

export type ProductIdentifier = {
  columnName: string;
  value: string;
};

export type CreateCleanQueryRequestBody = {
  identifiers: ProductIdentifier[];
};

export type WebScrapeRequestBody = {
  url: string;
};

export type EnrichProductFieldsRequestBody = {
  identifiers: ProductIdentifier[];
  enrichmentGroups: EnrichmentGroup[];
  sourceData: string;
};

export type SourceEnrichmentValues = { [key: string]: string | number | boolean };

export type SummariseEnrichmentResultsRequestBody = {
  results: SourceEnrichmentGroupResult[];
};

export type SourceEnrichmentSummaries = {
  summary: string;
  groupSummaries: { [key: string]: string };
};

export type SuggestEnrichmentGroupOptions = {
  productDescription: string;
  enrichmentGroups: EnrichmentGroup[];
};
