import { ArrowPathIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { FileUpload, LoadingSpinner } from '../../../components';
import clsx from 'clsx';
import { SectionHeader } from './SectionHeader';
import { EnrichmentConfigPanelSources } from './EnrichmentConfigPanelSources';
import { EnrichmentDataTable } from './EnrichmentDataTable';
import { EnrichmentConfigPanelFields } from './EnrichmentConfigPanelFields';
import { EnrichmentConfigPanelProduct } from './EnrichmentConfigPanelProduct';
import { useImportedData } from './ImportedData';
import { EnrichmentActionToolbar } from './EnrichmentActionToolbar';

export function EnrichmentDemo() {
  const {
    setFiles,
    activeImportedData,
    importedData,
    reloadImportedData,
    removeImportedData,
    activeFileName,
    setActiveFileName,
    identifierHeadersSelected
  } = useImportedData();

  return (
    <div>
      <SectionHeader title="Product Enrichment" subtitle="Configure your required enrichment set up below." />

      <div className="my-8 flex justify-between gap-y-4 gap-x-12 flex-col lg:flex-row">
        <div className="w-full flex flex-col gap-y-6">
          <EnrichmentConfigPanelProduct />
          <EnrichmentConfigPanelSources />
        </div>
        <div className="w-full">
          <EnrichmentConfigPanelFields />
        </div>
      </div>
      <div className="my-4">
        <FileUpload
          onFileAdd={(newFiles) => {
            setFiles(newFiles);
          }}
          accept=".csv"
        />
      </div>
      <div className="my-4">
        <p className="my-2">{Object.keys(importedData).length > 0 ? 'Imported Data' : 'No files imported'}</p>
        <div className="flex flex-col gap-2">
          {Object.entries(importedData).map(([fileName, importedFile]) => (
            <div
              key={fileName}
              className={clsx(
                'flex justify-between items-center py-2 px-4 w-full shadow-md border-2 border-gray-300 rounded-md',
                importedFile.error && activeFileName === fileName
                  ? 'border-red-600'
                  : activeFileName === fileName
                  ? 'border-2 border-green-600 text-black'
                  : 'text-gray-400'
              )}
            >
              <button
                disabled={importedFile.loading}
                onClick={() => {
                  setActiveFileName(fileName);
                }}
              >
                {fileName}
              </button>
              <div className="flex gap-x-4">
                {importedFile.canReload && (
                  <button
                    className="text-gray-800 flex justify-center items-center h-5 w-5"
                    disabled={importedFile.loading}
                    onClick={() => {
                      reloadImportedData(fileName);
                    }}
                  >
                    {importedFile.loading ? <LoadingSpinner size="xs" /> : <ArrowPathIcon />}
                  </button>
                )}
                <button
                  className="text-gray-800 flex justify-center items-center h-5 w-5"
                  disabled={importedFile.loading}
                  onClick={() => {
                    removeImportedData(fileName);
                  }}
                >
                  {<MinusCircleIcon />}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {activeImportedData && (
        <div className="my-12">
          <EnrichmentActionToolbar />
          <p className="text-sm">
            Select the column headers which identify the product then use the actions to AI generate a source list,
            enrich or view results.
          </p>
          <EnrichmentDataTable
            headers={activeImportedData.headers}
            rows={activeImportedData.data}
            enrichmentDisabled={!identifierHeadersSelected}
          />
        </div>
      )}
    </div>
  );
}
