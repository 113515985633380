import {
  ImageSearchResult,
  SourceEnrichmentFieldResult,
  SourceEnrichmentGroupResult,
  SourceEnrichmentResponse,
  SourceEnrichmentSummaries
} from './types';

type StartProductEnrichmentAction = {
  type: 'START_PRODUCT_ENRICHMENT';
  payload: { fileName: string; rowId: string; enrichmentGroups: SourceEnrichmentGroupResult[] };
};

type ClearEnrichmentFileResultsAction = {
  type: 'CLEAR_ENRICHMENT_FILE_RESULTS';
  payload: { fileName: string };
};

type ClearEnrichmentRowResultAction = {
  type: 'CLEAR_ENRICHMENT_ROW_RESULT';
  payload: { fileName: string; rowId: string };
};

type UpsertEnrichmentRowCleanQueryAction = {
  type: 'UPSERT_ENRICHMENT_ROW_CLEAN_QUERY';
  payload: { fileName: string; rowId: string; cleanQuery: string };
};

type SetEnrichmentRowErrorAction = {
  type: 'SET_ENRICHMENT_ROW_ERROR';
  payload: { fileName: string; rowId: string; error: string };
};

type UpsertEnrichmentRowResultAction = {
  type: 'UPSERT_ENRICHMENT_ROW_RESULT';
  payload: { fileName: string; rowId: string; data: SourceEnrichmentResponse };
};

type UpsertEnrichmentRowResultSourceFieldsAction = {
  type: 'UPSERT_ENRICHMENT_ROW_RESULT_SOURCE_FIELDS';
  payload: {
    fileName: string;
    rowId: string;
    enrichmentGroups: SourceEnrichmentGroupResult[];
  };
};

type UpsertResultSummariesAction = {
  type: 'UPSERT_RESULT_SUMMARIES';
  payload: {
    fileName: string;
    rowId: string;
    resultSummaries: SourceEnrichmentSummaries;
  };
};

type UpsertEnrichmentRowResultImagesAction = {
  type: 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES';
  payload: { fileName: string; rowId: string; images: ImageSearchResult[] };
};

type CompleteProductEnrichmentAction = {
  type: 'COMPLETE_PRODUCT_ENRICHMENT';
  payload: { fileName: string; rowId: string; duration: number };
};

type CompleteEnrichingFields = {
  type: 'COMPLETE_ENRICHING_FIELDS';
  payload: { fileName: string; rowId: string };
};

type EnrichmentResultsAction =
  | StartProductEnrichmentAction
  | ClearEnrichmentFileResultsAction
  | ClearEnrichmentRowResultAction
  | UpsertEnrichmentRowCleanQueryAction
  | SetEnrichmentRowErrorAction
  | UpsertEnrichmentRowResultAction
  | CompleteEnrichingFields
  | UpsertEnrichmentRowResultSourceFieldsAction
  | UpsertResultSummariesAction
  | UpsertEnrichmentRowResultImagesAction
  | CompleteProductEnrichmentAction;

export type RowEnrichmentResultState = {
  data?: SourceEnrichmentResponse;
  loading: boolean;
  error?: string;
};

export type FileEnrichmentResult = {
  [rowId: string]: RowEnrichmentResultState;
};

export type EnrichmentResultState = {
  [fileName: string]: FileEnrichmentResult;
};

export function enrichmentResultsReducer(
  state: EnrichmentResultState,
  action: EnrichmentResultsAction
): EnrichmentResultState {
  switch (action.type) {
    case 'START_PRODUCT_ENRICHMENT': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...(state[action.payload.fileName] || {}),
          [action.payload.rowId]: {
            loading: true,
            error: undefined,
            data: {
              groups: action.payload.enrichmentGroups
            }
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_CLEAN_QUERY': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              cleanQuery: action.payload.cleanQuery
            }
          }
        }
      };
    }

    case 'SET_ENRICHMENT_ROW_ERROR': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            loading: false,
            error: action.payload.error
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_RESULT': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName]?.[action.payload.rowId],
            data: action.payload.data,
            error: undefined
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              images: action.payload.images
            },
            error: undefined
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_RESULT_SOURCE_FIELDS': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              groups: action.payload.enrichmentGroups
            },
            error: undefined
          }
        }
      };
    }

    case 'UPSERT_RESULT_SUMMARIES': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              summary: action.payload.resultSummaries.summary,
              groups: state[action.payload.fileName][action.payload.rowId].data!.groups.map((group) => {
                const summary =
                  !!action.payload.resultSummaries?.groupSummaries &&
                  group.groupName in action.payload.resultSummaries.groupSummaries
                    ? action.payload.resultSummaries.groupSummaries[group.groupName]
                    : '';
                return {
                  ...group,
                  summary
                };
              })
            },
            error: undefined
          }
        }
      };
    }

    case 'COMPLETE_ENRICHING_FIELDS': {
      const groupsWithStats = state[action.payload.fileName][action.payload.rowId].data!.groups.map((group) => {
        const { nullFields, nonNullFields } = splitEnrichmentGroupResultFields(group);
        const enrichedCount = nonNullFields.length;
        const nullCount = nullFields.length;
        const totalFieldsCount = enrichedCount + nullCount;
        const enrichedPct = totalFieldsCount > 0 ? Math.round((enrichedCount / totalFieldsCount) * 100) : 0;

        return {
          ...group,
          stats: {
            enrichedCount,
            enrichedPct,
            nullCount
          }
        };
      });
      const overallStats = calculateOverallStats(groupsWithStats);

      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              groups: groupsWithStats,
              stats: overallStats
            }
          }
        }
      };
    }

    case 'COMPLETE_PRODUCT_ENRICHMENT': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...(state[action.payload.fileName] || {}),
          [action.payload.rowId]: {
            ...state[action.payload.fileName]?.[action.payload.rowId],
            loading: false,
            error: undefined,
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              processingTime: action.payload.duration
            }
          }
        }
      };
    }

    case 'CLEAR_ENRICHMENT_FILE_RESULTS': {
      const newState = { ...state };
      delete newState[action.payload.fileName];
      return newState;
    }

    case 'CLEAR_ENRICHMENT_ROW_RESULT': {
      const newState = { ...state };
      delete newState[action.payload.fileName][action.payload.rowId];
      return newState;
    }

    default:
      throw new Error(`unknown enrichment results action type`);
  }
}

export function splitEnrichmentGroupResultFields(group: SourceEnrichmentGroupResult) {
  const nullFields: SourceEnrichmentFieldResult[] = [];
  const nonNullFields: SourceEnrichmentFieldResult[] = [];

  for (const field of group.fields) {
    if (field.values.every((value) => value.value === null || value.value === 'null')) {
      nullFields.push(field);
    } else {
      const values = field.values.filter((value) => {
        return !!value.value && value.value !== 'null';
      });
      if (values.length > 0) {
        nonNullFields.push({
          ...field,
          values
        });
      }
    }
  }

  return { nullFields, nonNullFields };
}

function calculateOverallStats(groups: SourceEnrichmentGroupResult[]) {
  const enrichedCount = groups.reduce((acc, curr) => acc + curr.stats!.enrichedCount, 0);
  const nullCount = groups.reduce((acc, curr) => acc + curr.stats!.nullCount, 0);
  const totalFieldsCount = enrichedCount + nullCount;
  const enrichedPct = totalFieldsCount > 0 ? Math.round((enrichedCount / totalFieldsCount) * 100) : 0;

  return { enrichedCount, nullCount, enrichedPct };
}
