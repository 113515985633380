import { ArrowDownIcon, ChevronDoubleRightIcon, PlayIcon, StopIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ProductIdentifiersRow, useProductEnrichment } from './Enrichment';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { useImportedData } from './ImportedData';
import clsx from 'clsx';

export function EnrichmentActionToolbar() {
  const { enrichmentGroups, sources, sourceBlacklist, sourceLimit, enrichAllBatchSize, setEnrichAllBatchSize } =
    useEnrichmentConfig();
  const { activeImportedData, activeFileName, getProductRowIdentifiers, identifierHeadersSelected } = useImportedData();
  const {
    enrichmentResults,
    enrichAllLoading,
    downloadLoading,
    enrichAllStopped,
    skipEnriched,
    clearFileResults,
    enrichAllProducts,
    stopEnrichAll,
    toggleSkipEnriched,
    downloadSummary,
    downloadDetailedResults,
    downloadImages
  } = useProductEnrichment();

  return (
    <div className="my-4 flex items-center">
      <div className="flex flex-col justify-center items-start pt-2 pr-4 border-r-2">
        <h3>Enrich All</h3>
        <div className="flex flex-row justify-end items-center gap-x-2">
          <button
            className="px-4 py-2 bg-red-300 rounded-md hover:bg-red-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1"
            onClick={() => {
              stopEnrichAll();
            }}
            disabled={enrichAllStopped || !enrichAllLoading}
          >
            <span className="w-5">
              <StopIcon />
            </span>
          </button>
          <button
            className={clsx(
              'px-4 py-2 rounded-md disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1',
              skipEnriched ? 'bg-blue-300 hover:bg-blue-200' : 'bg-gray-300'
            )}
            onClick={() => {
              toggleSkipEnriched();
            }}
          >
            <span className="w-5">
              <ChevronDoubleRightIcon />
            </span>
          </button>
          <button
            className="px-4 py-2 bg-green-300 rounded-md hover:bg-green-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1"
            disabled={!identifierHeadersSelected || enrichAllLoading}
            onClick={() => {
              const rows: ProductIdentifiersRow[] = activeImportedData!.data.map((row) => ({
                rowId: row.id,
                identifiers: getProductRowIdentifiers(row.id)
              }));
              enrichAllProducts({
                batchSize: enrichAllBatchSize,
                rows,
                fileName: activeFileName!,
                options: {
                  enrichmentGroups,
                  sourceBlacklist,
                  sourceLimit,
                  sources: sources.map((source) => source.name)
                }
              });
            }}
          >
            <span className="w-5">
              <PlayIcon />
            </span>
          </button>
          <div className="disabled:bg-gray-300 flex justify-center items-center gap-x-1">
            <input
              type="number"
              className="pl-4 pr-2 rounded-md w-16"
              value={enrichAllBatchSize}
              onChange={(event) => {
                const { value } = event.target;
                const intValue = parseInt(value, 10);
                setEnrichAllBatchSize(intValue);
              }}
            />
            <p className="w-10 text-xs text-gray-600">Batch Size</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-start pt-2 px-4">
        <h3>Results</h3>
        <div className="flex flex-row justify-end items-center gap-x-2">
          <button
            className="px-4 py-2 bg-green-300 rounded-md hover:bg-green-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1 text-sm"
            disabled={
              !activeFileName ||
              downloadLoading ||
              !identifierHeadersSelected ||
              Object.keys(enrichmentResults).length === 0
            }
            onClick={() => {
              const rowIdentifiers = activeImportedData!.data.map((row) => ({
                rowId: row.id,
                identifiers: getProductRowIdentifiers(row.id)
              }));
              downloadSummary(activeFileName!, activeImportedData!.headers, rowIdentifiers);
            }}
          >
            <span className="w-5">
              <ArrowDownIcon />
            </span>
            <span>Summary</span>
          </button>
          <button
            className="px-4 py-2 bg-green-300 rounded-md hover:bg-green-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1 text-sm"
            disabled={
              !activeFileName ||
              downloadLoading ||
              !identifierHeadersSelected ||
              Object.keys(enrichmentResults).length === 0
            }
            onClick={() => {
              const rowIdentifiers = activeImportedData!.data.map((row) => ({
                rowId: row.id,
                identifiers: getProductRowIdentifiers(row.id)
              }));
              downloadDetailedResults(activeFileName!, activeImportedData!.headers, rowIdentifiers);
            }}
          >
            <span className="w-5">
              <ArrowDownIcon />
            </span>
            <span>Detailed</span>
          </button>
          <button
            className="px-4 py-2 bg-green-300 rounded-md hover:bg-green-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1 text-sm"
            disabled={
              !activeFileName ||
              downloadLoading ||
              !identifierHeadersSelected ||
              Object.keys(enrichmentResults).length === 0
            }
            onClick={() => {
              const rowIdentifiers = activeImportedData!.data.map((row) => ({
                rowId: row.id,
                identifiers: getProductRowIdentifiers(row.id)
              }));
              downloadImages(activeFileName!, rowIdentifiers);
            }}
          >
            <span className="w-5">
              <ArrowDownIcon />
            </span>
            <span>Images</span>
          </button>
          <button
            className="px-4 py-2 bg-red-300 rounded-md hover:bg-red-200 disabled:bg-gray-300 disabled:cursor-default flex justify-center items-center gap-x-1 text-sm"
            disabled={
              !activeFileName || enrichAllLoading || downloadLoading || Object.keys(enrichmentResults).length === 0
            }
            onClick={() => {
              clearFileResults(activeFileName!);
            }}
          >
            <span className="w-5">
              <XMarkIcon />
            </span>
            <span>Clear</span>
          </button>
        </div>
      </div>
    </div>
  );
}
