import clsx from 'clsx';
import { BoltIcon, EyeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from '../../../components';
import { EnrichmentDataTableHeader, EnrichmentDataTableRow } from './types';
import { ViewSourceEnrichmentResultModal } from './ViewSourceEnrichmentResultModal';
import { useState } from 'react';
import { useImportedData } from './ImportedData';
import { useActiveFileEnrichmentResults, useProductEnrichment } from './Enrichment';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { RowEnrichmentResultState } from './enrichmentResultsReducer';

type Props = {
  headers: EnrichmentDataTableHeader[];
  rows: EnrichmentDataTableRow[];
  enrichmentDisabled: boolean;
};

export function EnrichmentDataTable(props: Props) {
  const [viewSourceEnrichmentResultModal, setViewSourceEnrichmentResultModal] =
    useState<RowEnrichmentResultState | null>(null);
  const { enrichmentGroups, sources, sourceBlacklist, sourceLimit } = useEnrichmentConfig();
  const { updateActiveHeaderKind, activeFileName, getProductRowIdentifiers } = useImportedData();
  const { enrichProductRow, enrichmentResults, clearRowResult, enrichAllLoading } = useProductEnrichment();
  const activeFileResults = useActiveFileEnrichmentResults(activeFileName || undefined);

  return (
    <div className="my-4 w-full shadow-md border-2 border-gray-300 rounded-md overflow-x-auto">
      <ViewSourceEnrichmentResultModal
        result={viewSourceEnrichmentResultModal}
        isOpen={!!viewSourceEnrichmentResultModal}
        onClose={() => {
          setViewSourceEnrichmentResultModal(null);
        }}
      />
      <table className="w-full text-left">
        <thead>
          <tr>
            {props.headers.map((header) => (
              <th
                className={clsx(
                  'p-4 whitespace-nowrap cursor-pointer',
                  header.kind === 'identifier' ? 'text-green-600' : ''
                )}
                key={header.id}
                onClick={() => {
                  const newKind = header.kind === 'identifier' ? null : 'identifier';
                  updateActiveHeaderKind(header.id, newKind);
                }}
              >
                {header.name}
              </th>
            ))}
            <th className="p-4 whitespace-nowrap sticky right-0">
              <div className="font-medium text-left sr-only">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.rows.map((row, rowIdx) => {
            return (
              <tr className={clsx(rowIdx % 2 === 0 ? 'bg-gray-100' : '')} key={row.id}>
                {row.cells.map((cell) => (
                  <td key={cell.id} className={clsx('px-4 py-2', rowIdx % 2 === 0 ? 'bg-gray-100' : 'bg-gray-50')}>
                    {cell.value.slice(0, 50)}
                    {cell.value.length > 50 ? '...' : ''}
                  </td>
                ))}
                <td
                  className={clsx(
                    'pl-2 py-2 bg-gradient-to-r from-transparent to-[12px] sticky right-0',
                    rowIdx % 2 === 0 ? 'to-gray-100' : 'to-gray-50'
                  )}
                >
                  <div className="flex flex-col justify-center items-end gap-2 px-2">
                    <button
                      className="w-32 py-1 flex flex-row justify-center items-center gap-1 cursor-pointer disabled:cursor-default rounded-md bg-orange-300 hover:bg-orange-200 disabled:bg-gray-300"
                      disabled={
                        props.enrichmentDisabled ||
                        enrichAllLoading ||
                        (activeFileResults && activeFileResults[row.id] && activeFileResults[row.id].loading)
                      }
                      onClick={() => {
                        const identifiers = getProductRowIdentifiers(row.id);
                        enrichProductRow(activeFileName!, row.id, {
                          identifiers,
                          enrichmentGroups,
                          sourceBlacklist,
                          sourceLimit,
                          sources: sources.map((source) => source.name)
                        });
                      }}
                    >
                      <span className="w-4 h-4">
                        <BoltIcon />
                      </span>
                      <span className="whitespace-nowrap text-sm">AI Enrich</span>
                    </button>
                    <button
                      className="w-32 py-1 flex flex-row justify-center items-center gap-1 cursor-pointer disabled:cursor-default rounded-md bg-blue-300 hover:bg-blue-200 disabled:bg-gray-300"
                      disabled={
                        !(activeFileResults && activeFileResults[row.id] && activeFileResults[row.id].data) ||
                        (activeFileResults && activeFileResults[row.id] && activeFileResults[row.id].loading)
                      }
                      onClick={() => {
                        if (activeFileName) {
                          setViewSourceEnrichmentResultModal(enrichmentResults[activeFileName][row.id]);
                        }
                      }}
                    >
                      <span className="flex flex-row justify-center items-center gap-1">
                        {activeFileResults && activeFileResults[row.id] && activeFileResults[row.id].loading ? (
                          <span className="w-6 h-6">
                            <LoadingSpinner size="xs" />
                          </span>
                        ) : (
                          <span className="w-6 h-6">
                            <EyeIcon />
                          </span>
                        )}
                        <span className="whitespace-nowrap text-sm">Results</span>
                      </span>
                    </button>
                    <button
                      className="w-32 py-1 flex flex-row justify-center items-center gap-1 cursor-pointer disabled:cursor-default rounded-md bg-red-300 hover:bg-red-200 disabled:bg-gray-300"
                      disabled={
                        !(activeFileResults && activeFileResults[row.id] && activeFileResults[row.id].data) ||
                        (activeFileResults && activeFileResults[row.id] && activeFileResults[row.id].loading)
                      }
                      onClick={() => {
                        if (activeFileName) {
                          clearRowResult(activeFileName, row.id);
                        }
                      }}
                    >
                      <span className="flex flex-row justify-center items-center gap-1">
                        <span className="w-6 h-6">
                          <XMarkIcon />
                        </span>
                        <span className="whitespace-nowrap text-sm">Clear</span>
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
