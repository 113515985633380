import { ImageSearchResultMap, SourceEnrichmentGroupResult } from './types';
import { saveAs } from 'file-saver';
import { ProductIdentifiersRow } from './Enrichment';
import { FileEnrichmentResult, RowEnrichmentResultState } from './enrichmentResultsReducer';
import { enrichmentClient } from './api';

export function getIdentifyingData(headers: string[], inputData: string[][], identifierHeaders: string[]): string[][] {
  const identIndices: number[] = [];

  for (const identHeader of identifierHeaders) {
    const index = headers.indexOf(identHeader);
    if (index > -1) {
      identIndices.push(index);
    }
  }

  const result = inputData.map((row) => row.filter((_val, idx) => identIndices.includes(idx)));

  return result;
}

export function createOutputHeader(identifierHeaders: string[], resultState: FileEnrichmentResult) {
  const resultStateArr = Object.values(resultState);
  if (resultStateArr.length === 0) {
    throw new Error(`no results in result state`);
  }

  const resultStateArrFieldLengths = resultStateArr
    .filter((resultStateRow) => !!resultStateRow.data?.groups)
    .map((resultStateRow) => resultStateRow.data!.groups!.flatMap((group) => group.fields).length);

  const maxFieldsLength = Math.max(...resultStateArrFieldLengths);

  const maxFieldsLengthResultsIndex = resultStateArrFieldLengths.findIndex((value) => value === maxFieldsLength);

  if (maxFieldsLengthResultsIndex < 0) {
    throw new Error(`error finding longest fields length result`);
  }

  const maxFieldsResult = resultStateArr[maxFieldsLengthResultsIndex];

  const allEnrichmentFields = maxFieldsResult.data!.groups!.flatMap((group) =>
    group.fields.map((field) => field.fieldName)
  );
  const dataHeader = createOutputHeaderRow(identifierHeaders, allEnrichmentFields);
  const summaryDataHeader = createSummaryOutputHeaderRow(identifierHeaders, allEnrichmentFields);

  return { dataHeader, summaryDataHeader };
}

export function createOutputData(
  identifierData: ProductIdentifiersRow[],
  enrichmentResults: (RowEnrichmentResultState | null)[],
  columnCount: number
): { data: string[][]; summaryData: string[][]; images: ImageSearchResultMap } {
  const data: string[][] = [];
  const summaryData: string[][] = [];
  const images: ImageSearchResultMap = {};

  enrichmentResults.forEach((enrichmentResult, resultIdx) => {
    const rowIdentifierData = identifierData[resultIdx].identifiers.map((col) => col.value);
    if (!enrichmentResult) {
      const emptyRow = createEmptyOutputDataRow(rowIdentifierData, columnCount);
      data.push(emptyRow);
      return;
    }
    const rowIdentifier = createRowIdentifierString(rowIdentifierData);
    images[rowIdentifier] = enrichmentResult.data?.images || [];

    const rowCount = enrichmentResult.data!.groups!.reduce((acc, currGroup) => {
      const maxValueCountInGroup = currGroup.fields.reduce(
        (acc, currField) => (currField.values.length > acc ? currField.values.length : acc),
        0
      );
      return maxValueCountInGroup > acc ? maxValueCountInGroup : acc;
    }, 0);

    for (let i = 0; i <= rowCount; i++) {
      const row = createOutputDataRow(rowIdentifierData, i, enrichmentResult.data!.groups!);
      data.push(row);
      if (i === 0) {
        // For summary output, only include the first enriched value for each field
        const summaryRow = createSummaryOutputDataRow(rowIdentifierData, enrichmentResult.data!.groups!);
        summaryData.push(summaryRow);
      }
    }
  });

  return { data, summaryData, images };
}

export function createImagesOutput(
  identifierData: ProductIdentifiersRow[],
  enrichmentResults: FileEnrichmentResult
): ImageSearchResultMap {
  return identifierData.reduce((images, ident, resultIdx) => {
    const enrichmentResult = enrichmentResults[ident.rowId];
    if (enrichmentResult) {
      const rowIdentifierData = ident.identifiers.map((col) => col.value);
      const rowIdentifier = createRowIdentifierString(rowIdentifierData);
      images[rowIdentifier] = enrichmentResult.data?.images || [];
    }
    return images;
  }, {} as ImageSearchResultMap);
}

function createRowIdentifierString(rowIdentifierData: string[]): string {
  return rowIdentifierData.map((ident) => ident.replace(/[^a-z0-9]/gi, '_').toLowerCase()).join('-');
}

function createOutputHeaderRow(identifierHeaders: string[], enrichmentFields: string[]): string[] {
  const result: string[] = [];

  result.push(...identifierHeaders);
  result.push('Priority');
  result.push(
    ...enrichmentFields.flatMap((field) => [
      field,
      `${field} (Source)`,
      `${field} (Source URL)`,
      `${field} (Kind)`,
      `${field} (Enrichment Group)`
    ])
  );

  return result;
}

function createSummaryOutputHeaderRow(identifierHeaders: string[], enrichmentFields: string[]): string[] {
  const result: string[] = [];

  result.push(...identifierHeaders);
  result.push(...enrichmentFields);

  return result;
}

function createOutputDataRow(
  identifiers: string[],
  valueIndex: number,
  enrichmentGroups: SourceEnrichmentGroupResult[]
): string[] {
  const result: string[] = [];

  result.push(...identifiers);
  result.push((valueIndex + 1).toString());
  result.push(
    ...enrichmentGroups.flatMap((group) =>
      group.fields.flatMap((field) => {
        const fieldValue = field.values[valueIndex];
        if (!fieldValue) {
          return ['', '', '', '', ''];
        }
        const fieldValueStr = fieldValue.value ? fieldValue.value.toString() : '';
        return [fieldValueStr, fieldValue.sourceName, fieldValue.sourceUrl, field.kind, group.groupName];
      })
    )
  );

  return result;
}

function createSummaryOutputDataRow(identifiers: string[], enrichmentGroups: SourceEnrichmentGroupResult[]): string[] {
  const result: string[] = [];

  result.push(...identifiers);
  result.push(
    ...enrichmentGroups.flatMap((group) =>
      group.fields.flatMap((field) => {
        if (field.values.length === 0) {
          return [''];
        }
        const firstDefinedFieldValue = field.values.find((value) => !!value.value && value.value !== 'null');
        const fieldValueStr = firstDefinedFieldValue ? firstDefinedFieldValue.value!.toString() : '';

        return [fieldValueStr];
      })
    )
  );

  return result;
}

function createEmptyOutputDataRow(identifiers: string[], columnCount: number): string[] {
  const result: string[] = [];
  const enrichmentColumnCount = columnCount - identifiers.length - 1;

  result.push(...identifiers);
  result.push('0');
  Array.from({ length: enrichmentColumnCount }).forEach((_) => {
    result.push('');
  });

  return result;
}

export function escapeCsvValue(value: string) {
  if (typeof value === 'string' && (value.includes(',') || value.includes('"') || value.includes('\n'))) {
    // Escape double quotes by doubling them and wrap the whole value in double quotes
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
}

export function saveCsv(filename: string, data: string) {
  const outputCsvBlob = new Blob([data], { type: 'text/plain;charset=utf-8' });
  saveAs(outputCsvBlob, filename);
}

export async function remoteDownloadImagesZip(imageResultMap: ImageSearchResultMap) {
  try {
    const zipBlob = await enrichmentClient.imageDownload(imageResultMap);

    // Create a URL for the blob
    const url = window.URL.createObjectURL(zipBlob);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'images.zip'; // The name for the downloaded file
    document.body.appendChild(a); // Append the anchor to the DOM
    a.click(); // Programmatically trigger a click to start the download
    document.body.removeChild(a); // Remove the anchor from the DOM

    // Release the URL object
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error('Failed to download images', err);
  }
}
